.isometric_section {
  overflow-y: hidden;
  overflow-x: hidden;
  scroll-behavior: initial;
  position: relative;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;

}

.isometric_section::-webkit-scrollbar {
  display: none;
}

.isoinfo{
  position:sticky;
  top:10vh;
  left:5vw;
  color:#fff;
  width: 20vw;
  z-index: 1;
}

.isoheader{
  font-size:2.5em;
  padding-bottom: 15px;
}

.isotext{
  font-size:.9em;
  padding-bottom: 10px;
}


.flexbox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100vw;
}

.flexbox .item {
  position: relative;
  width: 20%;
}
.flexbox .item img {
  width: 100%;
  display: block;
}

.grid {
	position: absolute;
	width: 70%;
  left: 30%;
  right: auto;
	/*3D Transform*/
	transform: rotateX(60deg) rotateZ(30deg);
	/*Preserve 3D style for children*/
	transform-style: preserve-3d;
}
.grid li {
  transition: 0.2s ease;
	margin: 25px;
	list-style-type: none;
	/*!*Hide the lighting gradient from the front face*!*/
	/*background-size: 0, cover;*/
	/*Preserve 3D style for children(pseudo elements)*/
	transform-style: preserve-3d;
}
.grid li:hover {
  transform: translate3d(-50px,-50px,0);
}
/*Left and Bottom Sides*/
.grid li:before, .grid li:after {
	content: '';
	position: absolute;
	/*100% height and width*/
	top: 0; left: 0; right: 0; bottom: 0;
	background: inherit;
	background-size: cover;
}
/*Left Side*/
.grid li:before {
	transform-origin: left center;
	transform: rotateY(90deg);
	/*Reducing the width of the left side for a flat feel*/
	width: 0px;
}
/*Bottom Side*/
.grid li:after {
	transform-origin: bottom center;
	transform: rotateX(90deg);
	/*Reducing the height*/
	height: 0px; top: auto; bottom: 0;
	/*Background fix - start the background from the bottom*/
	background-position: bottom center;
}
/*Shadow*/
.s {
	position: absolute;
	/*30px smaller from each side*/
	top: 30px; left: 30px; right: 30px; bottom: 30px;
	background: rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 30px 30px rgba(0, 0, 0, 0.4);
	/*Pushing down the shadow to give an elevated feel*/
	transform: translateZ(-10px);
}

.portfolio-section{
  width: 100%;
  left: 0;
  transform: rotateX(50deg) rotateZ(30deg);
}

.portfolio-section .item {
  position: relative;
  width: 50%;
}

@media (max-width: 1700px) {
  .isoheader{
    line-height: normal;
  }
}
@media (max-width: 900px) {
  .isoinfo{
    width: 50vw;
  }
}

@media (max-width: 600px) {
  .isoinfo{
    top: 5vh;
    background-color: rgba(19,43,71,.9);
    inline-size: 70vw;
  }

  .isoheader{
    padding: 5%;
  }

  .isotext{
    padding: 5%;
  }

  .flexbox {
    height: 100vh;
  }

  .flexbox .item {
    position: relative;
    width: 100%;
  }

  .flexbox .item img {
    width: 100%;
    display: block;
  }

  .grid {
    position: absolute;
    top: auto;
    width: 33.333%;
    left: -20vw;
    right: 0;
    transform: rotateX(0deg) rotateZ(0deg);
  }

  .portfolio-section{
    display: none;
}

}
